import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const Container = styled.div`
  margin-top: 30px;

  @media ${device.s} {
    margin-top: 20px;
  }
`
export const Section = styled.div``

export const YearList = styled.ul`
  font-size: 15px;
  padding: 10px 0;

  @media ${device.s} {
    padding: 0;
  }
`

export const Year = styled.li`
  padding-right: 15px;
  text-align: right;
  width: 60px;
  display: block;
  float: right;

  a {
    text-decoration: none;
  }
`

export const MonthList = styled.ul`
  font-size: 15px;
  padding: 10px 0;

  @media ${device.s} {
    padding: 0;
  }
`
export const Month = styled.li`
  padding: 10px 15px 10px 0px;
  text-align: right;
  width: 190px;
  display: block;
  float: right;

  a {
    text-decoration: none;
  }

  @media ${device.s} {
    width: 60px;
  }
`

export const ArticleListHeader = styled.div`
  border-bottom: 1px solid #d9d9d9;
  font-size: 15px;
  padding: 10px 0;
  display: flex;
`

export const DateHeader = styled.span`
  padding-right: 2%;
  text-align: right;
  width: 15%;
  display: block;
  float: right;
  font-weight: 700;

  @media ${device.s} {
    width: 30%;
  }
`
export const TitleHeader = styled.span`
  width: 80%;
  display: block;
  float: right;
  font-weight: 700;

  @media ${device.s} {
    width: 68%;
  }
`
export const ArticleList = styled.ul`
  font-size: 15px;
  padding: 0px;
  margin: 0px 0px 50px 0px;

  @media ${device.s} {
    padding: 0;
  }
`
export const Article = styled.li`
  border-bottom: 1px solid #d9d9d9;
  font-size: 15px;
  padding: 15px 0;
  display: flex;

  a {
    text-decoration: none;
    color: rgb(0, 0, 238);
  }
`
export const ArticleDate = styled.span`
  padding-right: 2%;
  text-align: right;
  width: 15%;
  display: block;
  float: right;

  @media ${device.s} {
    width: 30%;
  }
`
export const ArticleTitle = styled.span`
  width: 80%;
  display: block;
  float: right;

  @media ${device.s} {
    width: 68%;
  }
`
