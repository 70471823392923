import React from 'react'
import { Link } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import { siteMapMetaInfo } from '../../constants/metaInfo'
import SEO from '../seo'
import { Container, Section, YearList, Year } from './styles'

const MainHtmlSiteMap = ({ pageContext, location }) => {
  const seoTitle = siteMapMetaInfo.title
  const seoDescription = siteMapMetaInfo.description
  const canonicalUrl = `${
    process.env.GATSBY_BASE_URL
  }${location.pathname.substring(1)}`

  const mainSiteMapData = pageContext.data

  const sections = mainSiteMapData.sections.filter(
    (item) => item.id !== 'sports'
  )

  return (
    <>
      <SEO
        title={seoTitle}
        lang="ar"
        description={seoDescription}
        canonicalUrl={canonicalUrl}
      />
      <div>
        <h1>خريطة موقع الشرق - الفهرس الرئيسي</h1>
        {!isEmpty(sections) && (
          <Container>
            {sections.map((section) => {
              if (section && !isEmpty(section.data)) {
                return (
                  <Section key={section.id}>
                    <h4>{section.label.ar || section.label.en}</h4>
                    <YearList>
                      {section.data.map((year) => (
                        <Year key={year.id}>
                          <Link to={`/${section.slug}/sitemap-${year.label}/`}>
                            {year.label}
                          </Link>
                        </Year>
                      ))}
                    </YearList>
                  </Section>
                )
              }
            })}
          </Container>
        )}
      </div>
    </>
  )
}

export default MainHtmlSiteMap
