import React, { useEffect } from 'react'

import Layout from '../components/layout'
import MainHtmlSiteMap from '../components/siteMap/mainHtmlSiteMap'
import YearHtmlSiteMap from '../components/siteMap/yearHtmlSiteMap'
import MonthHtmlSiteMap from '../components/siteMap/monthHtmlSiteMap'

import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const SiteMapMain = ({ pageContext, location }) => {
  const {
    isDefaultSiteMap = false,
    isYearSiteMap = false,
    isMonthSiteMap = false
  } = pageContext

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Layout location={location} lang={'ar'}>
      {isDefaultSiteMap && (
        <MainHtmlSiteMap pageContext={pageContext} location={location} />
      )}
      {isYearSiteMap && (
        <YearHtmlSiteMap pageContext={pageContext} location={location} />
      )}
      {isMonthSiteMap && (
        <MonthHtmlSiteMap pageContext={pageContext} location={location} />
      )}
    </Layout>
  )
}

export default SiteMapMain
