import React from 'react'
import { isEmpty, sortBy } from 'lodash'
import { Link } from 'gatsby'

import { siteMapMetaInfo } from '../../constants/metaInfo'
import SEO from '../seo'

import { Container, MonthList, Month } from './styles'

const YearHtmlSiteMap = ({ pageContext, location }) => {
  const data = pageContext.yearData || []
  const articleSection = data[0] || {}
  const { pages = [], label } = articleSection || {}
  let articleSectionData = {}

  const year = pageContext.year || ''
  const sectionSlug = pageContext.sectionSlug || 'article'

  const seoTitle = siteMapMetaInfo.title
  const seoDescription = siteMapMetaInfo.description
  const canonicalUrl = `${
    process.env.GATSBY_BASE_URL
  }${location.pathname.substring(1)}`

  if (!isEmpty(pages)) {
    articleSectionData = pages[0]
    // sort the months
    if (articleSectionData && !isEmpty(articleSectionData.months)) {
      const sortedMonths = sortBy(
        articleSectionData.months,
        (month) => month.id
      )
      articleSectionData.months = sortedMonths
    }
  }

  return (
    <>
      <SEO
        title={seoTitle}
        lang="ar"
        description={seoDescription}
        canonicalUrl={canonicalUrl}
      />
      <div>
        <h1>{label.ar || ''}</h1>
        {!isEmpty(articleSectionData) && !isEmpty(articleSectionData.months) ? (
          <Container>
            <div>
              <h4>{articleSectionData.section.label.ar || ''}</h4>
              <MonthList>
                {articleSectionData.months.map((month) => (
                  <Month key={month.id}>
                    <Link
                      to={`/${sectionSlug}/sitemap-${year.label}-${month.id}/`}
                    >
                      {month.label.ar}
                    </Link>
                  </Month>
                ))}
              </MonthList>
            </div>
          </Container>
        ) : (
          <Container>
            <div>لا توجد مقالات للعرض</div>
          </Container>
        )}
      </div>
    </>
  )
}

export default YearHtmlSiteMap
