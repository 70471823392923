import React from 'react'
import { isEmpty, orderBy } from 'lodash'
import { Link } from 'gatsby'
import { parseISO, format } from 'date-fns'
import { siteMapMetaInfo } from '../../constants/metaInfo'
import SEO from '../seo'
import { sendEventItemClick } from '../../utils/sendEvent'

import {
  Container,
  ArticleListHeader,
  DateHeader,
  TitleHeader,
  ArticleList,
  Article,
  ArticleDate,
  ArticleTitle
} from './styles'

const MonthHtmlSiteMap = ({ pageContext, location }) => {
  const data = pageContext.data
  const seoTitle = siteMapMetaInfo.title
  const seoDescription = siteMapMetaInfo.description
  const canonicalUrl = `${
    process.env.GATSBY_BASE_URL
  }${location.pathname.substring(1)}`

  if (data && !isEmpty(data.items)) {
    data.items = orderBy(data.items, (item) => item.date, 'desc')
  }

  const handleLinkClick = (item) => {
    sendEventItemClick({
      itemId: `${item.id}`,
      itemType: 'article'
    })
  }

  return (
    <>
      <SEO
        title={seoTitle}
        lang="ar"
        description={seoDescription}
        canonicalUrl={canonicalUrl}
      />
      <div>
        {!isEmpty(data) && (
          <>
            <h1>{data.label.ar || ''}</h1>
            <Container>
              {data && data.items && data.items.length > 0 ? (
                <>
                  <ArticleListHeader>
                    <DateHeader> تاريخ </DateHeader>
                    <TitleHeader> لقب</TitleHeader>
                  </ArticleListHeader>
                  <ArticleList>
                    {data.items.map(
                      (item) =>
                        item.id &&
                        item.date &&
                        item.title && (
                          <Article key={item.id}>
                            <ArticleDate>
                              {format(parseISO(item.date), 'yyyy-MM-dd')}
                            </ArticleDate>
                            <ArticleTitle>
                              <Link
                                to={
                                  item.slug && item.slug.includes('latest-news')
                                    ? `${item.slug}`
                                    : `/ar/${item.slug}/`
                                }
                                onClick={() => handleLinkClick(item)}
                                state={{ fromSitemap: true }}
                              >
                                {item.title}
                              </Link>
                            </ArticleTitle>
                          </Article>
                        )
                    )}
                  </ArticleList>
                </>
              ) : (
                <div>لا توجد مقالات للعرض</div>
              )}
            </Container>
          </>
        )}
      </div>
    </>
  )
}

export default MonthHtmlSiteMap
